import { DOMHelpers } from 'helpers/dom';
import { isInjectable } from 'interfaces/injectable';
import 'ko-components';
import 'libraries/core.admin';
import { extendedAppContainer } from './configuration/inversify/container/extended';

window.HELP_IMPROVE_VIDEOJS = false;
window.WCC = WCC;
window.TimeZoneNameGenerator = TimeZoneNameGenerator;
window.$ = $;
window.ko = ko;
window._ = _;
window.moment = moment;

function getPage() {
    switch (window.wccRouteId) {
        case 'home/brochure': return import('pages/admin/home/brochure/page'); 
        case 'home/login': return import('pages/admin/home/login/page');
        case 'home/signup': return import('pages/admin/home/signup/page');
        case 'home/browserNotSupported': return import('pages/admin/home/browserNotSupported/page');

        case 'account/login': return import('pages/admin/account/login/page');
        case 'account/edit': return import('pages/admin/account/edit/page');
        case 'account/password/restore': return import('pages/admin/account/password/restore/page');
        case 'account/password/choose': return import('./pages/admin/account/password/choose/page');
        case 'account/twofactorauth': return import('pages/admin/account/twofactorauth/page');
        case 'account/acceptterms': return import('pages/admin/account/acceptterms/page');

        case 'tasks/index': return import('pages/admin/tasks/index/page');

        case 'discussions/projects': return import('pages/discussions/projects/projectsPage');
        case 'discussions/projectDashboard': return import('pages/admin/discussions/projectDashboard/dashboardVM');
        case 'discussions/projectCharts': return import('pages/admin/discussions/projectDashboard/externalChartsVM');
        case 'discussions/activities': return import('pages/discussions/activities/activitiesPage');
        case 'discussions/engagement': return import('pages/discussions/engagement/engagementPage');
        case 'discussions/participants': return import('pages/admin/discussions/participants/page');
        case 'discussions/confirmAction': return import('pages/admin/discussions/confirmAction/page');
        case 'discussions/edit': return import('pages/admin/discussions/edit/page');
        case 'discussions/people/addedit': return import('pages/admin/discussions/people/addedit/page');
        case 'discussions/scheduleandpay': return import('pages/admin/discussions/payment/scheduleandpay/page');
        case 'discussions/PaymentExtension': return import('pages/admin/discussions/payment/extension/page');        
        case 'discussions/rewards': return import('pages/admin/discussions/rewards/page');
        case 'discussions/analysis': return import('pages/admin/discussions/analysis/page');
        case 'discussions/sentimentOptions': return import('pages/discussions/sentimentOptions/page');
        case 'discussions/files': return import('pages/admin/discussions/files/page');
        case 'discussions/uploadFile': return import('pages/admin/discussions/files/upload/page');
        case 'discussions/transcriptionEditor': return import('pages/admin/discussions/transcriptionEditor/page');

        case 'community/pagesBrowse': return import('pages/admin/community/siteContent/pagesBrowse/page');
        case 'community/filesBrowse': return import('pages/admin/community/siteContent/filesBrowse/page');
        case 'community/pageEditor': return import('pages/admin/community/pageEditor/page');
        case 'community/polls/browse': return import('pages/admin/community/polls/browse/page');
        case 'community/emailtemplates': return import('pages/admin/community/emailtemplates/index/page');
        case 'community/pagePreview': return import('pages/admin/community/pagePreview/page');

        case 'payments/invoices': return import('pages/admin/payments/invoices/page');
        case 'payments/purchase': return import('pages/admin/purchase/page');
        case 'payments/pay': return import('pages/admin/pay/page');

        case 'buy/sms': return import('pages/admin/buy/buySMSVM');

        case 'timeline/index': return import('pages/admin/timeline/page');
        
        case 'people/import': return import('pages/admin/people/import/page');

        case 'healthcheck/index': return import('pages/admin/healthcheck/index/page');
        case 'healthcheck/logs': return import('pages/admin/healthcheck/logs/page');
        
        case 'emailbatches': return import('pages/admin/emailbatches/index/page');
        case 'emailbatches/add': return import('pages/admin/emailbatches/add/page');
        case 'smsbatches/index': return import('pages/admin/smsbatches/index/page');
        case 'smsbatches/add': return import('pages/admin/smsbatches/add/page');
        case 'smsmessages/index': return import('pages/admin/smsmessages/index/page');

        case 'system/contentFrame': return import('components/contentFrame/page/page');
        case 'system/deleteAction': return import('pages/admin/system/deleteAction/page');

        case 'settings/spa': return import('pages/admin/settings/spa/settingsSPAVM');

        case 'settings/index': return import('pages/admin/settings/index/page');
        case 'settings/defaulttexts': return import('pages/admin/settings/defaulttexts/page'); 
        case 'settings/files': return import('pages/admin/settings/files/page');
        case 'settings/theme': return import('pages/admin/settings/theme/page');
        case 'settings/transactions': return import('pages/admin/settings/transactions/page');
        case 'settings/emails': return import('pages/admin/settings/emails/page');
        case 'settings/sms': return import('pages/admin/settings/sms/page');
        case 'settings/wscintegration': return import('pages/admin/settings/wscintegration/page');

        case 'systemlogs': return import('pages/admin/systemlogs/page');

        case 'superadmin/accounts': return import('pages/admin/superadmin/accounts/index/page');
        case 'superadmin/servicereportingworkitems': return import('pages/admin/superadmin/servicereportingworkitems/index/page');
        case 'superadmin/serviceworkitems': return import('pages/admin/superadmin/serviceworkitems/index/page');
        case 'superadmin/servicetasks': return import('pages/admin/superadmin/servicetasks/index/page');
        case 'superadmin/statements': return import('pages/admin/superadmin/statements/index/page');
        case 'superadmin/transactions': return import('pages/admin/superadmin/transactions/index/page');
        case 'superadmin/videos': return import('pages/admin/superadmin/videos/index/page');

        case 'superadmin/spa': return import('pages/admin/superadmin/spa/superadminSPAVM');

        case 'todos/index': return import('./pages/admin/todos/todosVM');
        
        case 'policies': return import('pages/admin/community/pagePreview/page');
        case 'pricing': return import('pages/admin/pricing/index/page');
        case 'error': return import('pages/admin/error/page');

        case 'openairequests': return import('pages/admin/superadmin/spa/pages/openAIRequests/openAIRequestsStandalonePage');
        case 'anthropicrequests': return import('pages/admin/superadmin/spa/pages/anthropicRequests/anthropicRequestsStandalonePage');
        
        default: throw new Error('Route not found: ' + window.wccRouteId);
    }
}

function buildPage(ctor) {
    let page;

    if (isInjectable(ctor)) {
        page = extendedAppContainer.resolve(ctor);
    } else {
        page = new ctor();
    }

    callSafely(page, 'beforeInit');
    callSafely(page, 'init');

    return null;
}

function callSafely(obj, methodName, args) {
    if (obj[methodName] != null)
        obj[methodName].apply(obj, args);
}

/**
 * 
 * @param {boolean} enable
 */
function toggleAwaiter(enable) {
    var $wrapperNode = $('#workArea');

    if (enable) {
        var $spinner = $('<div class="wcc-page-awaiter"><div class="wcc-fa-spinner wcc-fa-spinner-fixed wcc-faded"><img src="/Images/qualzy-spinner.gif" alt="" /></div></div>'),
            $spinnerContainer = $('<div data-bind="hideTemplate:true"></div>');

        $spinnerContainer.append($spinner);
        
        $wrapperNode.css({ 'visibility': 'hidden' });
        $wrapperNode.after($spinnerContainer);

        ko.applyBindings({}, $spinner[0]);
    } else {
        $wrapperNode.css({ 'visibility': '' });
        $wrapperNode.parent().find('.wcc-page-awaiter').each((idx, node) => ko.removeNode(node));
    }

    return null;
}

var oPageLoaded = ko.observable(false);

var awaiterTask = DOMHelpers.waitFor('#workArea', oPageLoaded)
    .then(() => toggleAwaiter(true))
    .catch(_.noop);

var pageReadyTask = oPageLoaded.when();

new Promise(resolve => $(document).ready(resolve))
    .then(() => getPage())
    .then(Module => Module.__esModule ? Module.default : Module)
    .then(Module => components.awaitAll().then(() => Module))
    .then(Page => buildPage(Page))
    .then(() => oPageLoaded(true));    

Promise.all([awaiterTask, pageReadyTask]).then(() => toggleAwaiter(false));